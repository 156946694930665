import React from 'react'
import Bradcom from '../../Bradcom/Main'


const CoreTeamDetails = () => {
    const team1 = [
        // {
        //     imgSrc: "assets/images/team/9.jpg",
        //     title: "K Jayalakshmi",
        //     description: 'After successful completion of ACS she has had an impactful career spanning over 6 years immensely contributing to her clientile in areas of company law and compliance. She has worked with multiple startups and MSMEs.'
        // },
        {
            imgSrc: "assets/images/team/7.jpg",
            title: "Balaji P",
            description: 'Carries over 15 Years of Finance Experience in Vehicle Finance space. Was earlier associated with Shriram Finance, Cholamandalam Finance and Indostar Capital.'
        },
        {
            imgSrc: "assets/images/team/8.jpg",
            title: "R Settu",
            description: "Carries over 15 years of Finance Experience in Vehicle finance space. Was earlier associated with Shriram Finance."
        },
        {
            imgSrc: "assets/images/team/10.jpg",
            title: "J Venkatesh",
            description: "Carries over 10 years of experience in Loan Originating System  and Loan Mgt System. He was earlier associated with Equitas Small Finance Ltd and Indostar Capital Finance Ltd."
        },
        {
            imgSrc: "assets/images/team/4.jpg",
            title: "N Uma",
            description: 'Highly experienced vehicle finance professional with 15+ years in branch management, disbursement, HR,  and recovery. Dedicated to leveraging this knowledge for impactful contributions.',
            // desc: "Bringing over 15 years of extensive finance experience within the vehicle finance sector, has previously held roles at Shriram Finance and Indostar Capital. Her tenure spans a diverse range of responsibilities, including branch administration, loan disbursement, HR coordination, cash management, recovery management, and dedicated to leveraging this wealth of experience to drive success and innovation in every aspect."
        }

    ];
    const team = [{
        imgSrc: "assets/images/team/10.jpg",
        title: "J Venkatesh",
        description: "Carries over 10 years of experience in Loan Originating System  and Loan Mgt System. He was earlier associated with Equitas Small Finance Ltd and Indostar Capital Finance Ltd."
    },
    {
        imgSrc: "assets/images/team/4.jpg",
        title: "N Uma",
        description: 'Highly experienced vehicle finance professional with 15+ years in branch management, disbursement, HR,  and recovery. Dedicated to leveraging this knowledge for impactful contributions.',
        // desc: "Bringing over 15 years of extensive finance experience within the vehicle finance sector, has previously held roles at Shriram Finance and Indostar Capital. Her tenure spans a diverse range of responsibilities, including branch administration, loan disbursement, HR coordination, cash management, recovery management, and dedicated to leveraging this wealth of experience to drive success and innovation in every aspect."
    }];

  return (
    <>
        <Bradcom title={"Home"} subtitle={"Team Details"} common={"Core Team"}/>
        <section className="service-page pt-120 pb-30 serviceshight carddd">
                <div className="container"  >
                    <div className='col-lg-6'>
                        <div className="block-title text-left">
                            <h1>Meet our Team</h1>
                            <p>Core Team</p>
                        </div>
                    </div>
                    <div className="row carddd">
                        {team1.map((team1, index) => (
                            <div className="col-lg-4 col-md-6 mb-60" key={index}>
                                <div className="service-one__card">
                                    <div className="team-one__image">
                                        <img src={team1.imgSrc} className="team-one__image" />
                                    </div>
                                    <div className="service-one__content">
                                        <h3>{team1.title}</h3>
                                        <p>{team1.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
       
    </>
  )
}

export default CoreTeamDetails