import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React  from 'react'
function Location() {
  return (
    <>
        <div className="client-carousel pt-50 pb-50 ">
        
            <div className="container">  
            {/* <div className="block-title text-center">
                        <h3>Existing  Offices / Locations</h3>
                    </div> */}
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:1500}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <h2>                        <a href="https://maps.app.goo.gl/9zX1uzzfVLxKnAa86" target="_blank" rel="noopener noreferrer">Chennai</a> 
</h2>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>
                        <a href="https://maps.app.goo.gl/XE929iPDEGcGkekp9" target="_blank" rel="noopener noreferrer">Namakkal</a>
                            </h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/LWKcb8C9zqj2ViTh8" target="_blank" rel="noopener noreferrer">Villupuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/55qCrkncMzUpZ3vM9" target="_blank" rel="noopener noreferrer">Thirukoviloor</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/QV19qsnjXSeTK8Tv6" target="_blank" rel="noopener noreferrer">Kallakurichi</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/XnhTRkVq1BsWmKp49" target="_blank" rel="noopener noreferrer">Chinnasalem</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/pCmH9tG1kp6gV1Lj6" target="_blank" rel="noopener noreferrer">Sankarapuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/Y824hKeQhxgGtsVFA" target="_blank" rel="noopener noreferrer">Manaloorpet</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/kZ9yhgT3DoWu71nf9" target="_blank" rel="noopener noreferrer">Ananthapuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/cVfbJGnDiGiQDCEDA" target="_blank" rel="noopener noreferrer">Rasipuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/5PaTQUCuAhceuejM7" target="_blank" rel="noopener noreferrer">Athur</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/pNMyEQE272H4qK7w9" target="_blank" rel="noopener noreferrer">Panruti</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/7yLE42nvSxFzLiuy9" target="_blank" rel="noopener noreferrer">Melmaruvathur</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/nrnVCCX58J7kwfam7" target="_blank" rel="noopener noreferrer">Gingee</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/A3KZt86q7AhCZABy8" target="_blank" rel="noopener noreferrer">Arani</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://maps.app.goo.gl/6nHa9EVpK2g7dMDW9" target="_blank" rel="noopener noreferrer">Pondicherry</a>
</h2> 
                        </div>
                        </SwiperSlide>
                       {/*  <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Sankarapuram</h2> 
                        </div>
                       </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide> */}
                    </div>
                </Swiper>
            </div>
        </div>
    </>
  )
}

export default Location